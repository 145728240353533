import React, { Dispatch } from 'react'
import { Button, Checkbox, Modal, Spin} from 'antd';
import { useMutation } from '@tanstack/react-query';
import { Notification } from 'utils/notification';
import { AxiosError } from 'axios';
import instance from 'config/_axios';
import useGetAllData from 'hooks/useGetAllData';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { renderFullName } from 'utils/others_functions';

const AttendsModal = React.memo(({selectedItem, setselectedItem}: {selectedItem: any, setselectedItem: Dispatch<any>}) => {

  const { urlValue } = useUrlQueryParams({ currentPage: 1, perPage: 15 });

  const { data, isLoading } = useGetAllData({
    queryKey: ["timetable-attends/attend-student", urlValue.filter?.edu_year_id, selectedItem?.user_id],
    url: `timetable-attends/attend-student`,
    urlParams: {
      "per-page": 0, 
      filter: JSON.stringify({edu_year_id: urlValue.filter?.edu_year_id, student_user_id: selectedItem?.user_id}),
      expand: "timeTableDate,timeTableDate.para,timeTableDate.room,timeTableDate.building,subject,subjectCategory,timeTableDate.profile"
    },
    options: {
      enabled: !!selectedItem?.user_id && !!urlValue.filter?.edu_year_id
    }
  })  

  const { mutate, isLoading: updateLoading } = useMutation({
    mutationFn: async (newVals: any) => {
      const formdata = new FormData();
      formdata.append("reason", newVals?.isCheck);
      const response = await instance({
        url: `/timetable-attends/${newVals?.id}`,
        method: "PUT",
        data: formdata,
      });
      return response.data;
    },
    onSuccess: async (res) => {
      Notification("success", "update", res?.message);
    },
    onError: (error: AxiosError<any>) => {
      Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
    },
    retry: 0,
  });


  return (
    <>
      <Modal 
        title={`Sababli qilish (${renderFullName(selectedItem?.user)})`} 
        open={!!selectedItem} 
        onCancel={() => setselectedItem(undefined)}
        footer={[<><Button type='primary' onClick={() => setselectedItem(undefined)}>Yopish</Button></>]}
        width={1500}
      >
        <Spin spinning={updateLoading || isLoading} >
          <div className='grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 min-h-[200px]'>
            {
              data?.items?.map((item: any) => (
                <div key={item?.id}>
                  <Checkbox defaultChecked={item?.reason === 1} className='bg-blue-100 p-4 w-full rounded-md hover:bg-blue-200' onChange={(e) => mutate({isCheck: e.target.checked ? 1 : 0, id: item?.id})}>
                  <div>
                    <div className='flex'>
                      <p className='font-bold w-[80px]'>Sana:</p>
                      <p className='font-bold'>{item?.date}</p>
                    </div>
                    <div className='flex'>
                      <p className='font-bold w-[80px]'>Para:</p>
                      <p className='font-bold'>{item?.timeTableDate?.para?.name}</p>
                    </div>
                    <div className='flex'>
                      <p className='font-bold w-[80px]'>Xona:</p>
                      <p className='font-bold'>{item?.timeTableDate?.building?.name}/{item?.timeTableDate?.room?.name} - {item?.timeTableDate?.room?.room_type?.name}</p>
                    </div>
                    <div className='flex'>
                      <p className='font-bold w-[80px]'>Fan:</p>
                      <p className='font-bold'>{item?.subject?.name}/{item?.subjectCategory?.name}</p>
                    </div>
                    <div className='flex'>
                      <p className='font-bold w-[80px]'>O'qituvchi:</p>
                      <p className='font-bold'>{renderFullName(item?.timeTableDate?.profile)}</p>
                    </div>
                  </div>
                    
                  </Checkbox>
                </div>
              ))
            }
          </div>
        </Spin>
      </Modal>
    </>
  )
})

export default AttendsModal;