import { Button, Form, FormInstance, Image, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FILE_URL } from "config/utils";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PersonalElements from "pages/users/form_elements/personal_elements";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import checkPermission from "utils/check_permission";

interface DataType {
  name: string;
  value: ReactNode;
  value2?: ReactNode;
  value3?: ReactNode;
}

const MainInfoUserView = ({ data, form, saveMutation }: { data: any, form: FormInstance, saveMutation: UseMutationResult<any, AxiosError<any, any>, void, unknown> }) => {

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sharedOnCell = (_: DataType, index: number | undefined, type?: "last") => {
    if (index || index == 0) {
      if (index == 4) {
        return { colSpan: 0 }
      }
      if (index == 3) {
        return { colSpan: 1 }
      }
      if (type === "last") {
        return { colSpan: 0, rowSpan: 0 }
      }
      if (index < 3) {
        if (index == 0) {
          return { colSpan: 2, rowSpan: 3 }
        }
        return { colSpan: 0, rowSpan: 0 };
      }
    }
    return {};
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("Surname"),
      dataIndex: "name",
      rowScope: "row",
    },
    {
      title: t("Value"),
      dataIndex: "value",
      onCell: (_, index) => ({
        colSpan: (index == 4) ? 3 : 1
      }),
    },
    {
      title: t("Name2"),
      dataIndex: "value2",
      onCell: (_, index) => sharedOnCell(_, index),
      className: "bg-[#FAFAFA]"
    },
    {
      title: t("Name3"),
      dataIndex: "value3",
      onCell: (_, index) => sharedOnCell(_, index, "last"),
    },
  ];

  const tableData: DataType[] = [
    {
      name: t("Last name"),
      value: data?.profile?.last_name,
      value2: data?.profile?.image ?
        <Image
          width={120}
          src={FILE_URL + data?.profile?.image}
        /> : t("Image not loaded!")
    },
    {
      name: t("First name"),
      value: data?.profile?.first_name,
    },
    {
      name: t("Middle name"),
      value: data?.profile?.middle_name,
    },
    {
      name: t("Main phone number"),
      value: data?.profile?.phone,
      value2: t("Additional phone number"),
      value3: data?.profile?.phone_secondary
    },
    {
      name: t("Email"),
      value: data?.email,
    },
  ];

  useEffect(() => {
    if (saveMutation.isSuccess) setIsModalOpen(false)
  }, [saveMutation.isSuccess])

  return (
    <div className="px-[24px] pt-[15px] pb-[10px]">
      <div className="flex justify-between items-center mb-[12px]">
        <p className="font-medium text-[16px]">{t("Personal information")}</p>
        {checkPermission("user_update") ? <Button onClick={() => setIsModalOpen(true)}>{t("Edit")}</Button> : null}
      </div>
      <Table
        columns={columns}
        bordered
        dataSource={tableData}
        showHeader={false}
        pagination={false}
      />


      {/* edit form */}
      <Modal
        title={t("Personal information")}
        okText={t("Submit")}
        cancelText={t("Cancel")}
        width={1000}
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={(values) => saveMutation.mutate(values)}
        >
          <PersonalElements form={form} avatar={data?.profile?.image} />
        </Form>
      </Modal>
    </div>
  )
}
export default MainInfoUserView;