import React, { useState } from "react";
import { Col,DatePicker,message,Row, Tag } from "antd";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { useTranslation } from "react-i18next";
import HeaderExtraLayout from "components/HeaderPage/headerExtraLayout";
import FilterSelect, { TypeFilterSelect } from "components/FilterSelect";
import Table, { ColumnsType } from "antd/es/table";
import { number_order } from "utils/number_orders";
import useGetAllData from "hooks/useGetAllData";
import CustomPagination from "components/Pagination";
import dayjs from "dayjs";
import { renderFullName } from "utils/others_functions";
import SearchInputWithoutIcon from "components/SearchInput/searchInputWithoutIcon";
import { globalConstants } from "config/constants";
import { dateParserToDatePicker } from "utils/second_to_date";
import instance from "config/_axios";
import { excelExport } from "utils/excelExport";
import { ExcelBtn } from "components/Buttons";

const selectData: TypeFilterSelect[] = [
  {
    name: "faculty_id",
    label: "Faculty",
    url: "faculties",
    permission: "faculty_index",
    child_names: ["direction_id", "direction_id", "edu_plan_id", "group_id", "edu_semestr_id", "edu_semestr_subject_id"],
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 }
  },
  {
    name: "direction_id",
    label: "Direction",
    url: "directions",
    render: (e) => `${e?.code} ${e?.name}`,
    permission: "direction_index",
    parent_name: "faculty_id",
    child_names: ["edu_plan_id", "group_id", "edu_semestr_id", "edu_semestr_subject_id"],
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 }
  },
  {
    name: "edu_plan_id",
    label: "Edu plan",
    url: "edu-plans",
    permission: "edu-plan_index",
    parent_name: "direction_id",
    child_names: ["group_id", "edu_semestr_id", "edu_semestr_subject_id"],
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 }
  },
  {
    name: "edu_semestr_id",
    label: "Edu semestr",
    url: "/edu-semestrs",
    permission: "edu-semestr_index",
    parent_name: "edu_plan_id",
    child_names: ["edu_semestr_subject_id"],
    render: (e) => <div>{e?.name} {e?.status == 1 ? <Tag color="green" className="ml-3">Active</Tag> : ""}</div>,
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 },
  },
  {
    name: "edu_semestr_subject_id",
    label: "Edu semestr subject",
    url: "/edu-semestr-subjects?expand=subject",
    permission: "edu-semestr-subject_index",
    render: (e) => <>{e?.subject?.name}</>,
    parent_name: "edu_semestr_id",
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 },
  },
  {
    name: "group_id",
    label: "Group",
    url: "/groups",
    parent_name: "edu_plan_id",
    render: (e) => <>{e?.unical_name}</>,
    permission: "group_index",
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 },
  },
  {
    name: "week_id",
    label: "Weeks",
    url: "weeks",
    permission: "week_index",
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 }
  },
  {
    name: "para_id",
    label: "Paras",
    url: "paras",
    permission: "para_index",
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 }
  },
  {
    name: "building_id",
    label: "Building",
    url: "buildings",
    permission: "building_index",
    child_names: ["room_id"],
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 }
  },
  {
    name: "room_id",
    label: "Room",
    url: "rooms",
    permission: "room_index",
    parent_name: "building_id",
    render(e) {
        return `${e?.name}, ${e?.room_type?.name} ${e?.capacity}`
    },
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 }
  },
  {
    name: "attend_status",
    label: "Davomat statusi",
    permission: "room_index",
    staticData: [{id: "0", name: "Davomat qilinmagan"}, {id: "1", name: "Davomat qilingan"}],
    span: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 }
  },
];

const TimeTableNewByTeacher: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const [searchFName, setSearchFName] = useState<string | undefined>(undefined);
  const [searchLName, setSearchLName] = useState<string | undefined>(undefined);
  const [searchMName, setSearchMName] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false)

  const { urlValue, writeToUrl } = useUrlQueryParams({
    currentPage: 1,
    perPage: 10,
  });

  const [allData, setallData] = useState();

  const { data, isFetching } = useGetAllData({
    queryKey: ["timetable-dates/all", urlValue.filter_like?.start_date, urlValue.filter_like?.end_date, urlValue.filter, urlValue.filter_like?.attend_status, urlValue.currentPage, urlValue.perPage, searchFName, searchMName, searchLName],
    url: `timetable-dates/all`,
    urlParams: {
      expand: "subject,subjectCategory,week,para,user,user.profile,building,room,all,all.group,secondGroup,secondGroup.user,secondGroup.user.profile,secondGroup.building,secondGroup.room",
      "per-page": urlValue.perPage,
      page: urlValue.currentPage, 
      start_date: urlValue.filter_like?.start_date,
      end_date: urlValue.filter_like?.end_date,
      filter: JSON.stringify({
        ...urlValue.filter, 
        attend_status: urlValue.filter_like?.attend_status, 
        first_name: searchFName, 
        middle_name: searchMName, 
        last_name: searchLName
      }),
    },
    options: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "№",
      dataIndex: "order",
      width: 30,
      render: (_, __, i) =>
        number_order(
          urlValue.currentPage,
          urlValue.perPage,
          Number(i),
          isFetching
        ),
    },
    {
      title: t("Teacher"),
      dataIndex: "user",
      render: (e) => renderFullName(e?.profile),
    },
    {
      title: t("Subject"),
      key: "subject",
      render: (i, e) => `${e?.subject?.name}, ${e?.subjectCategory?.name}`,
    },
    {
      title: t("Week"),
      dataIndex: "week",
      render: (e) => e?.name,
    },
    {
      title: t("Para"),
      dataIndex: "para",
      render: (e) => e?.name,
    },
    {
      title: t("Group"),
      render: (e) => e?.all?.map((i: any) => <Tag className="my-1">{i?.group?.unical_name}</Tag>),
    },
    {
      title: t("Building"),
      dataIndex: "building",
      render: (e) => e?.name,
    },
    {
      title: t("Room"),
      dataIndex: "room",
      render: (e) => `${e?.name}, ${e?.room_type?.name} ${e?.capacity}`,
    },
    {
      title: t("Date"),
      dataIndex: "date",
    },
    {
      title: t("Attend"),
      render: (e) => <Tag color={e?.attend_status === 0 ? "error" : "success"} className="text-[15px] py-1" >{e?.attend_status === 0 ? "Davomat qilinmagan!" : <>Davomat qilingan! <br /> {dateParserToDatePicker(e?.updated_at)}</>}</Tag>,
    },
    {
      title: t("Ikkiga bo'linish"),
      render: (e) => e?.two_group == 1 ? <Tag color={"success"} >{e?.group_type}</Tag> : "",
    },
  ]

  const exportExcelPasswords = async () => {
    const arr: any = [];

    setLoading(true);
    
    const res = await instance({
      method: "GET",
      url: `timetable-dates/all?expand=subject,subjectCategory,week,para,user,user.profile,building,room,all,all.group,secondGroup,secondGroup.user,secondGroup.user.profile,secondGroup.building,secondGroup.room`,
      params: { 
        "per-page": 0,
        start_date: urlValue.filter_like?.start_date,
        end_date: urlValue.filter_like?.end_date,
        filter: JSON.stringify({
          ...urlValue.filter, 
          attend_status: urlValue.filter_like?.attend_status, 
          first_name: searchFName, 
          middle_name: searchMName, 
          last_name: searchLName
        }), 
      }
    });

    res.data.data.items?.forEach((element: any) => {
      arr.push({
        ["F.I.SH"]: renderFullName(element?.user?.profile),
        ['Fan']: `${element?.subject?.name}, ${element?.subjectCategory?.name}`,
        ['Hafta kuni']: element?.week?.name,
        ['Para']: element?.para?.name,
        ["Guruhlar"]: element?.all?.map(((l: any)=> l?.group?.unical_name)).join(","),
        ["Bino"]: element?.building?.name,
        ['Xona']: `${element?.room?.name}, ${element?.room?.room_type?.name} ${element?.room?.capacity}`,
        ["Sana"]: element?.date,
        ["Davomat"]: element?.attend_status === 0 ? "Davomat qilinmagan!" : dateParserToDatePicker(element?.updated_at),
        ["Ikkiga bo'linish"]: element?.two_group == 1 ? element?.group_type : "",
      })
    })
    setLoading(false);

    excelExport(arr, `O'qituvchilar dars jadvali`)
  }

  return (
    <div className="">
      <HeaderExtraLayout
        breadCrumbData={[
          { name: "Home", path: "/" },
          { name: "O'qituvchilar dars jadvali", path: "" },
        ]}
        title={t("O'qituvchilar dars jadvali")}
        btn={
          <ExcelBtn onClick={exportExcelPasswords} loading={loading} text="O'qituvchilar dars jadvali" />
        }
      />

      <div className="p-6">
        <Row gutter={[12, 12]}>
          {
            selectData?.map((e, i) => (
              <FilterSelect key={i} {...e}/>
            ))
          }
          
        </Row>
        <Row gutter={[12, 12]} className="mt-4">
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <DatePicker placeholder="...dan" value={urlValue.filter_like?.start_date ? dayjs(urlValue.filter_like?.start_date, "YYYY-MM-DD") : undefined} allowClear={true} className="w-full" format={'YYYY-MM-DD'} onChange={(e) => writeToUrl({name: "start_date", value: e ? dayjs(e).format("YYYY-MM-DD") : undefined})} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <DatePicker placeholder="...gacha" value={urlValue.filter_like?.end_date ? dayjs(urlValue.filter_like?.end_date, "YYYY-MM-DD") : undefined} allowClear={true} className="w-full" format={'YYYY-MM-DD'} onChange={(e) => writeToUrl({name: "end_date", value: e ? dayjs(e).format("YYYY-MM-DD") : undefined})} />
          </Col>
        </Row>

        <Row gutter={[12, 12]} className='my-4'>
            <Col xs={24} sm={24} md={12} lg={6}>
              <SearchInputWithoutIcon
                setSearchVal={setSearchLName} 
                filterKey='last_name' 
                placeholder="Familiya bo'yicha qidirish" 
                duration={globalConstants.debounsDuration}
                width={"100%"}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <SearchInputWithoutIcon 
                setSearchVal={setSearchFName} 
                filterKey='first_name' 
                placeholder="Ism bo'yicha qidirish" 
                duration={globalConstants.debounsDuration}
                width={"100%"}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <SearchInputWithoutIcon 
                setSearchVal={setSearchMName} 
                filterKey='middle_name' 
                placeholder="Otasining ismi bo'yicha qidirish" 
                duration={globalConstants.debounsDuration}
                width={"100%"}
              />
            </Col>
          </Row>

        <div className="mt-4">
          <Table
            columns={columns}
            dataSource={data?.items.length ? data?.items?.sort((a, b) => a?.para_id - b?.para_id) : allData}
            pagination={false}
            loading={isFetching}
            scroll={{x: 1500}}
          />

          <CustomPagination
            totalCount={data?._meta.totalCount}
            currentPage={urlValue.currentPage}
            perPage={urlValue.perPage}
          />
        </div>
      </div>
    </div>
  )
}

export default TimeTableNewByTeacher;