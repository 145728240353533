import React, { useEffect, useState } from 'react'
import Table, { ColumnsType } from 'antd/es/table';
import { IStudent, IStudentAttendReason } from 'models/student';
import HeaderExtraLayout from 'components/HeaderPage/headerExtraLayout';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import useGetAllData from 'hooks/useGetAllData';
import { number_order } from 'utils/number_orders';
import checkPermission from 'utils/check_permission';
import { useNavigate } from 'react-router-dom';
import CustomPagination from 'components/Pagination';
import { Col, Row, Tag, Tooltip } from 'antd';
import FilterSelect, { TypeFilterSelect } from 'components/FilterSelect';
import { useTranslation } from 'react-i18next';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { renderFullName } from 'utils/others_functions';
import AttendsModal from './modal';
import SearchInputWithoutIcon from 'components/SearchInput/searchInputWithoutIcon';
import { globalConstants } from 'config/constants';

const selectData: TypeFilterSelect[] = [
  {
    name: "faculty_id",
    label: "Faculty",
    url: "faculties",
    permission: "faculty_index",
    child_names: ["edu_plan_id"],
    span: { xl: 4, lg: 12 }
  },
  {
    name: "edu_plan_id",
    label: "Edu plan",
    url: "edu-plans",
    permission: "edu-plan_index",
    parent_name: "faculty_id",
    span: { xl: 10, lg: 12 }
  },
  {
    name: "course_id",
    label: "Course",
    url: "courses",
    permission: "course_index",
    span: { xl: 4, lg: 12 }
  },
  {
    name: "edu_year_id",
    label: "Edu year",
    url: "edu-years",
    filter: {status: "all"},
    permission: "edu-year_index",
    render: (e) => <div>{e?.name}{e?.status ? <Tag color="success" className="ml-2" >Active</Tag> : null}</div>,
    span: { xl: 6, lg: 12 }
  },
]

const CheckAttancesNew = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { urlValue } = useUrlQueryParams({ currentPage: 1, perPage: 15 });
  const [allData, setAllData] = useState<IStudentAttendReason[]>([]);
  const [selectedItem, setselectedItem] = useState<any>(undefined);
  const [searchFName, setSearchFName] = useState<string | undefined>(undefined);
  const [searchLName, setSearchLName] = useState<string | undefined>(undefined);
  const [searchMName, setSearchMName] = useState<string | undefined>(undefined);

  const { data, isLoading, isFetching, refetch } = useGetAllData({
    queryKey: ["timetable-attends/get", urlValue.perPage, urlValue.currentPage, urlValue.filter_like?.sort, ...(Object.values(urlValue?.filter) ?? []), searchFName, searchMName, searchLName],
    url: `timetable-attends/get`,
    urlParams: {
      "filter-like": JSON.stringify(urlValue?.filter_like),
      "per-page": urlValue.perPage, 
      page: urlValue.currentPage,
      filter: JSON.stringify({faculty_id: urlValue?.filter?.faculty_id, edu_plan_id: urlValue?.filter?.edu_plan_id, course_id: urlValue?.filter?.course_id}),
      sort: urlValue.filter_like?.sort ?? "-id",
      edu_year_id: urlValue.filter?.edu_year_id,
      expand: "user,faculty,group,teacherAttends"
    },
    options: {
      onSuccess: (res) => {
        setAllData(res?.items);
        setselectedItem(undefined)
      },
      enabled: !!urlValue.filter?.edu_year_id
    }
  })

  useEffect(() => {
    if(typeof selectedItem === 'undefined'){
      refetch()
    }
  }, [selectedItem])

  const columns: ColumnsType<IStudent> = React.useMemo(() => [
    {
      title: '№',
      width: 40,
      showSorterTooltip: false,
      render: (_, __, i) => number_order(urlValue.currentPage, urlValue.perPage, Number(i), isLoading),
    },
    {
      title: t('FullName'),
      showSorterTooltip: false,
      render: (e) =>
        checkPermission("student_view11") ? (
          <span
            onClick={() => navigate(`/students/view/${e?.user?.id}`)}
            className="hover:text-[#2F54EB] underline cursor-pointer"
          >{renderFullName(e?.user)}</span>
        ) : (<span>{renderFullName(e?.user)}</span>),
    },
    {
      title: t('Group'),
      dataIndex: "group",
      render: (e) => e?.unical_name,
    },
    {
      title: t('Qoldirgan paralar soni'),
      align: "center",
      render: (e, i) => <span><Tag color='green'>{e?.teacherAttends?.reason}</Tag>/ <Tag color='red'>{e?.teacherAttends?.all - e?.teacherAttends?.reason}</Tag></span>,
    },
    // {
    //   title: t('File'),
    //   render: (e, i) => <span>{e?.file ? <a href={FILE_URL + e?.file} target="_blank"><DocumentArrowRight24Regular /></a> : <p className="text-zinc-400">Fayl yuklanmagan</p>}</span>,
    // },
    // {
    //   title: t('Description'),
    //   width: 100,
    //   render: (e, i) => <Popover content={e?.description} title={t('Description')} >
    //       <span className='line-clamp-1'>{e?.description}</span>
    //     </Popover>,
    // },
    {
      title: t("Actions"),
      width: 120,
      align: "center",
      render: (i) => <div className='flex justify-center'>

        {checkPermission("timetable-attend_update") ? 
          <Tooltip placement="topLeft" title={t("Edit")} className='mx-5'>
            <IoDocumentAttachOutline onClick={() => setselectedItem(i)} className="edit cursor-pointer text-orange-500 text-[22px]" />
          </Tooltip> : ""}
      </div>,
    },
  ], [data?.items]);
  
  return (
    <>
      <HeaderExtraLayout
        breadCrumbData={[
          { name: "Home", path: "/" },
          { name: "Confirmation of student attendance", path: "" },
        ]}
        title={'Confirmation of student attendance'}
      />
      <div className='p-4'>
        <div>
          <Row gutter={[12, 12]}>
            {selectData?.map((e, i) => (
              <FilterSelect {...e} />
            ))}
          </Row>
          <Row gutter={[12, 12]} className='my-4'>
            <Col xs={24} sm={24} md={12} lg={6}>
              <SearchInputWithoutIcon
                setSearchVal={setSearchLName} 
                filterKey='last_name' 
                placeholder="Familiya bo'yicha qidirish" 
                duration={globalConstants.debounsDuration}
                width={"100%"}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <SearchInputWithoutIcon 
                setSearchVal={setSearchFName} 
                filterKey='first_name' 
                placeholder="Ism bo'yicha qidirish" 
                duration={globalConstants.debounsDuration}
                width={"100%"}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <SearchInputWithoutIcon 
                setSearchVal={setSearchMName} 
                filterKey='middle_name' 
                placeholder="Otasining ismi bo'yicha qidirish" 
                duration={globalConstants.debounsDuration}
                width={"100%"}
              />
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={data?.items.length ? data?.items : allData}
            pagination={false}
            loading={isFetching}
            size="middle"
            className="mt-3"
            rowClassName="py-[12px]"
            scroll={{ x: 576 }}
          />
          {(data?._meta?.totalCount ?? 0) > 10 ? <CustomPagination totalCount={data?._meta.totalCount} currentPage={urlValue.currentPage} perPage={urlValue.perPage} /> : undefined}
        </div>
        
        <AttendsModal selectedItem={selectedItem} setselectedItem={setselectedItem}/>

      </div>
    </>
  )
}

export default CheckAttancesNew

/**
  *  timetable-reason_index
  *  timetable-reason_delete
  *  timetable-reason_create
  *  timetable-reason_update
*/